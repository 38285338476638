/* body {
  font-family: 'VT323', sans-serif;
  background: #000000;
}

body:after {
  content: '';
  display: block;
  position: fixed;
  top: 50%;
  right: 18%;
  left: 18%;
  border: .5vw solid white;
  bottom: 44%;
  padding-top: 39%;
  transform: translateY(-50%);
  box-sizing: border-box;
} */
/* 
.helper {
  position: fixed;
  bottom: 1rem;
  z-index: 100;
  color: #fff;
  text-align: center;
  left: 0;
  right: 0;
  pointer-events: none;
  font-size: 3vw;
  opacity: .5;
} */


.viewport {
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
}

.viewport:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 20%;
  background: #000000;
  z-index: 10;
}

.viewport:after {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 20%;
  background: #000000;
  z-index: 10;
}

.game {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  transform: translateY(-50%);
}

/* .results {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(100%);
  transition: transform 1s ease;
  color: #B53121;
  font-size: 6vw;
  text-shadow: 2px 2px 2px #fff;
}

.results.shown {
  transform: translateY(0);
} */

.columns {
  background-color: #FDCBC4;
  background-size: 100% 100%;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  background-position: 50%;
  border-style: ridge;
  /*background-repeat: no-repeat;*/
}

/* .columns.columns-top {
  background-image: "url(/wpms/images/minigame-slot-top.jpg)";
}

.columns.columns-center {
  background-image: "url(/wpms/images/minigame-slot-center.jpg)";
}

.columns.columns-bottom {
  background-image: "url(/wpms/images/minigame-slot-bottom.jpg)";
} */

.columns:after {
  content: '';
  display: block;
  padding-top: 100%;
}

.results{
  position:absolute;
  top:0;
  right:0;
  bottom:0;
  left:0;
  display:none; 
  align-items:center;
  justify-content:center;
  color: black;
  font-size:4em;
  font-weight: bold;
  -webkit-text-stroke: 2px white;

  &.shown{
    display:flex; 
  }
}

@keyframes transition-4 {
  0% {
    background-size: 40%;
  }

  100% {
    background-size: 70%;
  }
}
@keyframes transition-3 {
  0% {
    background-size: 70%;
  }

  100% {
    background-size: 100%;
  }
}
@keyframes transition-2 {
  0% {
    background-size: 100%;
  }

  100% {
    background-size: 130%;
  }
}

@keyframes transition-1 {
  0% {
    background-size: 130%;
  }

  100% {
    background-size: 90%;
  }
}

@keyframes transition-0 {
  0% {
    background-size: 90%;
  }

  100% {
    background-size: 40%;
  }
}