.preview-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}


.preview-centered-text {
  font-size: 1.1em;
  text-align: center;
  margin: auto;
  width: 75%;
}