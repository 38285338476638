#card-background-wrapper {
    position: relative;
    height: 912px;
    width: 570px;
}

#card-background-wrapper img {
    height: 100%;
}

#card-inner-border {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 32px;
    height: 848px;
    width: 506px;
    top: 0;
    left: 0;
}

#card-image-wrapper {
    position: absolute;
    top: 0;
    justify-content: center;
    align-items: center;
    height: 512px;
    width: 512px;
    text-align: center;
    z-index: -1;
}

#card-image-wrapper img {
    width: 512px;
    height: 512px;
}

#card-name {
    position: absolute; 
    width: 90%;
    top: 61.25%;
    text-align: center;
    vertical-align: auto;
    height: 32px;
}

#card-name p {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    color: #79b15b;
    font-family: "AtamiDisplay";
    padding: 0;
    line-height: 1;
    margin: 0;
}

#card-power {
    position: absolute;
    width: 90%;
    top: 70.5%;
    text-align: center;
}

#card-power p {
    color: #79b15b;
    font-family: 'Fjalla One', sans-serif;
    font-size: 28px;
    margin: 0;
}

#card-description {
    position: absolute;
    width: 85%;
    top: 78%;
    text-align: center;
    word-wrap: break-word;
}

#card-description p {
    text-align: center;
    color: #b0b4a2;
    /* font-family: "AtamiLight"; */
    font-size: 22px;
    line-height: 105%;
    margin: 0;
}

#card-elemental {
    position: absolute;
    width: 90%;
    top: 93%;
    text-align: center;
}

#card-elemental p {
    color: #00ffea;
    font-family: "AtamiLight";
    font-size: 22px;
    line-height: 150%;
    margin: 0;
}

@font-face {
    font-family: "AtamiDisplay";
    src: local("AtamiDisplay"),
     url("./fonts/Atami-Display.otf") format("opentype");
}

@font-face {
    font-family: "AtamiLight";
    src: local("AtamiLight"),
     url("./fonts/Atami-Light.otf") format("opentype");
}    